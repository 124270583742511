import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';

const Dim = ({ isLoading = false, onClose }) => {
  return (
    <Transition.Root show={isLoading} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => onClose && onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity flex justify-center items-center">
            <p className="text-gray-200 font-medium">Cargando...</p>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

Dim.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Dim;
