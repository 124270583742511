import React from 'react';
import PropTypes from 'prop-types';

const focusClasses = [
  'inline-flex items-center px-8 py-3',
  'bg-indigo-600 hover:bg-indigo-700',
  'border border-transparent shadow-sm rounded-md',
  'text-base font-medium text-white',
  'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
];

const Button = ({ children, onClick, type, disabled, className }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={[...focusClasses, className].join(' ')}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
