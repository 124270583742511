import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

const Logo = ({ logo, width = 96, height = 96 }) => {
  return (
    <a href="#">
      <span className="sr-only">Logo</span>
      <Image
        width={width}
        height={height}
        src={logo}
        alt=""
        className="rounded-full"
      />
    </a>
  );
};

Logo.propTypes = {
  logo: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
