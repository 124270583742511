import React from 'react';
import PropTypes from 'prop-types';
import Cart from './Cart';
import { getCartResume } from './../helpers';
import { XIcon, ChevronRightIcon } from '@heroicons/react/outline';
import AuthButton from './../../components/Auth/AuthAvatar';
import { useCheckout } from '../../hooks/useCheckout';
import useStore from '../../hooks/useStore';
import Image from 'next/image';
import { priceFormatter } from '@posy/helpers';
import { useTranslation } from 'react-i18next';

const { toPriceTag } = priceFormatter;

const Header = ({ children, onClose, hideClose }) => {
  return (
    <div className="flex justify-between items-center">
      <div>{children}</div>
      {!hideClose && (
        <button onClick={onClose}>
          <XIcon className="w-8 h-8" />
        </button>
      )}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  hideClose: PropTypes.func,
  onClose: PropTypes.func,
};

const CartContainer = ({
  isLargeScreen,
  shouldAdjust,
  readOnly = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const { cartItems, editItemQuantity, resetCart, goToCheckout, removeItem } =
    useCheckout();
  const { total, count } = getCartResume(cartItems);
  const { store } = useStore();

  if (!store?.catalog?.allowOnlineOrders) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center h-full">
        <Image
          width={128}
          height={128}
          src={'/empty-cart.png'}
          alt=""
          className="rounded-full"
        />
        <p className="text-gray-500">Modo exhibición</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-end px-4 pt-4 pb-2 mb--4">
        {isLargeScreen && <AuthButton />}
      </div>

      <Cart
        cartItems={cartItems}
        resetCart={resetCart}
        removeItem={removeItem}
        editItemQuantity={editItemQuantity}
        readOnly={readOnly}
        shouldAdjust={shouldAdjust}
      />
      {!readOnly && count > 0 && (
        <button
          type="button"
          className={`w-full text-right inline-flex justify-center items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4`}
          onClick={goToCheckout}
        >
          {count} items {total > 0 ? `por ${toPriceTag(total)}` : ''}
          <ChevronRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
        </button>
      )}

      {shouldAdjust && (
        <button
          type="button"
          className={`w-full text-right inline-flex justify-center items-center px-6 py-3 border border-indigo-700 shadow-sm text-base font-medium rounded-md text-indigo-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4`}
          onClick={onClose}
        >
          {t('continue')}
          <ChevronRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </>
  );
};

CartContainer.propTypes = {
  cartItems: PropTypes.array,
  onClose: PropTypes.func,
  removeItem: PropTypes.func,
  editItemQuantity: PropTypes.func,
  goToCheckout: PropTypes.func,
  isLargeScreen: PropTypes.bool,
  shouldAdjust: PropTypes.bool,
  isModal: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default CartContainer;
