import React from 'react';
import PropTypes from 'prop-types';

import AuthButton from '../Auth/AuthAvatar';

const MobileFooter = ({ allowOnlineOrders, ConfirmButton }) => {
  return (
    <div className="inset-x-0 bottom-0 py-4 flex items-center">
      <div className="flex-1 flex items-center ml-4">
        <AuthButton />
      </div>
      {allowOnlineOrders ? (
        ConfirmButton
      ) : (
        <p className="mr-4">Modo exhibición</p>
      )}
    </div>
  );
};

MobileFooter.propTypes = {
  allowOnlineOrders: PropTypes.bool,
  ConfirmButton: PropTypes.node,
};

export default MobileFooter;
