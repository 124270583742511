import React, { useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

const QuantityInput = ({
  visible = true,
  value,
  onHandleChange,
  isFractionable,
  isOutOfStock,
}) => {
  const color = isOutOfStock ? 'red' : 'black';
  const [input, setInput] = useState();

  useEffect(() => {
    if (value) {
      setInput(value);
      onHandleChange(value);
    }
  }, [value]);

  const setDelta = (delta) => {
    const n = getFormattedValue(input) + delta;
    const value = n > 0 ? n : input;
    setInput(value);
    onHandleChange(value);
  };

  const getFormattedValue = (value) => {
    if (isFractionable) {
      return parseFloat(value);
    } else {
      return parseInt(value);
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="flex shadow-sm border-2 border-gray-300 rounded-md">
      <button onClick={() => setDelta(-1)} className=" mr-1 rounded-md">
        <MinusIcon className="w-12 px-4" />
      </button>
      <input
        type="text"
        value={input}
        data-testid={'item-qty-input'}
        style={{ width: 50, color }}
        min={1}
        onBlur={(e) => {
          if (e.target.value.length === 0 || e.target.value === '0') {
            setInput('1');
            onHandleChange(1);
          }
        }}
        onChange={(e) => {
          if (e.target.value.includes('-')) {
            return;
          }
          const value = isFractionable
            ? e.target.value
            : e.target.value.replace('.', '');

          setInput(value);
          if (e.target.value.length > 0) {
            const val = getFormattedValue(value);
            onHandleChange(val);
          }
        }}
        className="text-center text-lg focus:ring-indigo-500 focus:border-indigo-500 border-none"
      />
      <button
        onClick={() => setDelta(1)}
        className="ml-1 rounded-md disabled:text-gray-400"
        disabled={isOutOfStock}
      >
        <PlusIcon className="w-12 px-4 relative" />
      </button>
    </div>
  );
};

QuantityInput.propTypes = {
  visible: PropTypes.bool,
  isOutOfStock: PropTypes.bool.isRequired,
  isFractionable: PropTypes.bool.isRequired,
  value: PropTypes.number,
  onHandleChange: PropTypes.func.isRequired,
};

export default QuantityInput;
