import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const UserMenu = ({ children, userEmail, options = [], isBottom }) => {
  const { t } = useTranslation();
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="sr-only">Open user menu</span>
          {children}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`origin-top-right absolute z-10 ${
            isBottom ? 'bottom-12' : 'right-0'
          } mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="px-4 py-2">
            <p className="text-sm">{t('signedInAs')}</p>
            <p className="text-sm font-medium text-gray-900 truncate">
              {userEmail}
            </p>
          </div>
          <hr />
          <div className="py-1">
            {options.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <a
                    onClick={item.onClick}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    )}
                  >
                    {item.name}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

UserMenu.propTypes = {
  children: PropTypes.node,
  userEmail: PropTypes.string,
  options: PropTypes.array,
  isBottom: PropTypes.bool,
};

export default UserMenu;
