/* eslint-disable react/prop-types */
/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { priceFormatter } from '@posy/helpers';

import QuantityInput from '../common/QuantityInput';
import { getCartResume } from './../helpers';

const { getItemGross, toPriceTag } = priceFormatter;

const Cart = ({
  readOnly,
  cartItems = [],
  editItemQuantity,
  removeItem,
  resetCart,
  isLarge,
  shouldAdjust,
}) => {
  const { t } = useTranslation();
  const { total, count } = getCartResume(cartItems);
  const quantityChange = (id) => (value) => {
    editItemQuantity(id, value);
  };

  if (cartItems.length === 0) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center h-full">
        <Image
          width={128}
          height={128}
          src={'/empty-cart.png'}
          alt=""
          className="rounded-full"
        />
        <p className="text-gray-500">{t('cart.emptyPlaceholder')}</p>
      </div>
    );
  }

  return (
    <div className="px-4 flex-1 overflow-y-scroll">
      <div className="flex justify-between items-end mb-4">
        {count > 0 && !isLarge && (
          <div>
            <p className="text-gray-800 font-bold text-xl">
              {toPriceTag(total)}
            </p>
            <p className="text-gray-500 text-sm">{count} items</p>
          </div>
        )}

        {!readOnly && (
          <button className="ml-2 text-sm text-indigo-700" onClick={resetCart}>
            {t('cart.emptyButton')}
          </button>
        )}
      </div>
      <ul role="list" className="-my-6 divide-y divide-gray-200 py-4">
        {cartItems.map((product) => (
          <CartItem
            key={product.id}
            product={product}
            readOnly={readOnly}
            quantityChange={quantityChange}
            onRemove={removeItem}
            shouldAdjust={shouldAdjust}
          />
        ))}
      </ul>
    </div>
  );
};

const CartItem = ({
  product,
  shouldAdjust,
  readOnly,
  quantityChange,
  onRemove,
}) => {
  const { isOutOfStock, isUnavailable, quantity, available } = product;
  const isFractionable = product.format === 'fraction';
  const isAdjustable = isOutOfStock || isUnavailable;
  const shouldEdit = shouldAdjust ? isAdjustable : !readOnly;
  const hasLeftUnits = product.available > 0;
  const isOutOfStockClient = quantity > available;

  const remove = () => onRemove(product.id);

  return (
    <li key={product.id} className="py-4 flex relative">
      <div className="flex-shrink-0 w-16 h-16 border border-gray-200 rounded-md overflow-hidden">
        <Image
          src={product.thumbnail || '/no-image.png'}
          alt={product.imageAlt}
          className="object-center object-cover"
          width={64}
          height={64}
        />
      </div>
      <div className="ml-4 flex-1 flex flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>
              <a href={product.href}>{product.name}</a>
            </h3>
            <p className="ml-4">{toPriceTag(getItemGross(product))}</p>
          </div>
          <p className="mt-1 text-sm text-gray-500">{product.color}</p>
        </div>

        <div className="flex items-end justify-between text-sm">
          {isUnavailable ? (
            <p className="text-red-700 text-sm text-center">
              Item no disponible
            </p>
          ) : (
            <div>
              {shouldEdit ? (
                <QuantityInput
                  visible={isOutOfStock ? hasLeftUnits : true}
                  value={product.quantity}
                  isOutOfStock={isOutOfStock ? isOutOfStockClient : false}
                  isFractionable={isFractionable}
                  onHandleChange={quantityChange(product.id)}
                />
              ) : (
                <div className="flex">
                  <span className="mr-1">{toPriceTag(product.price)} </span>
                  <span>{'x ' + product.quantity}</span>
                </div>
              )}
              {isOutOfStockClient && (
                <StockLabel
                  hasLeftUnits={hasLeftUnits}
                  available={product.available}
                />
              )}
            </div>
          )}
          {shouldEdit && (
            <div className="flex">
              <RemoveButton onPress={remove} />
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

const StockLabel = ({ hasLeftUnits, available }) => {
  return hasLeftUnits ? (
    <p className="text-red-700 text-xs text-center mt-2">
      Últimos {available} disponibles
    </p>
  ) : (
    <p className="text-red-700 text-sm text-center">Item fuera de stock</p>
  );
};

const RemoveButton = ({ onPress }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onPress}
      type="button"
      className="font-medium text-indigo-600 hover:text-indigo-500"
    >
      {t('remove')}
    </button>
  );
};

Cart.propTypes = {
  readOnly: PropTypes.bool,
  cartItems: PropTypes.array,
  editItemQuantity: PropTypes.func,
  removeItem: PropTypes.func,
  resetCart: PropTypes.func,
  isLarge: PropTypes.bool,
};

export default Cart;
