import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingDim from '../common/Dim';
import { ModalProvider } from '../Modal';
import LayoutContext from './LayoutContext';
import Banner from '../Banner';

const Layout = ({
  children,
  Floating,
  FloatingBar,
  isLargeScreen,
  isCheckout,
  isLoading = false,
  isIndex,
  shouldEnableAds,
}) => {
  const isLarge = Boolean(isLargeScreen);
  const [open, setOpen] = useState(isLarge);
  const [hideFooter, setHideFooter] = useState(false);
  const value = { hideFooter, setHideFooter };
  const shouldEnableBanner = isIndex && shouldEnableAds;
  const data = shouldEnableBanner ? 164 : 96;

  useEffect(() => {
    setOpen(isLarge);
  }, [isLarge]);

  const shouldShowFloatingBar = () => {
    return !isLarge && !hideFooter;
  };

  return (
    <ModalProvider>
      <LayoutContext.Provider value={value}>
        <div className="flex">
          {isCheckout ? (
            <>
              {open && (
                <div className="overflow-y-scroll bg-gray-100 h-screen flex-1 px-8 absolute w-full lg:relative">
                  {Floating()}
                </div>
              )}
              <div className={`${isLargeScreen ? 'w-1/2' : 'w-full'}`}>
                {children}
              </div>
            </>
          ) : (
            <>
              <div
                className={`flex flex-col lg:px-8 lg:overflow-y-scroll lg:h-screen ${
                  isLargeScreen ? 'w-2/3' : 'w-full'
                }`}
                style={{
                  paddingBottom: shouldShowFloatingBar() ? data : 0,
                }}
              >
                {children}
              </div>
              {open && (
                <div className="flex flex-col bg-gray-100 flex-1 p-2 lg:overflow-y-scroll lg:h-screen">
                  {Floating}
                  <Banner visible={shouldEnableBanner} />
                </div>
              )}
            </>
          )}
          {shouldShowFloatingBar() && (
            <div
              className="fixed bottom-0 bg-gray-200 w-full"
              style={{ zIndex: 2 }}
            >
              {FloatingBar}
              <Banner visible={shouldEnableBanner} />
            </div>
          )}
        </div>
      </LayoutContext.Provider>{' '}
      <LoadingDim isLoading={isLoading} />
    </ModalProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  Floating: PropTypes.node,
  FloatingBar: PropTypes.node,
  isLargeScreen: PropTypes.bool,
  isCheckout: PropTypes.bool,
  isLoading: PropTypes.bool,
  isIndex: PropTypes.bool,
  shouldEnableAds: PropTypes.bool,
};

export default Layout;
